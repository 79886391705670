<template>
  <h1 class="title is-3">Nutzungsbedingungen</h1>
  <p></p>

  <!-- <h2 class="subtitle is-5">Lorem Ipsum</h2>
  <div class="content">
  </div> -->

  <h2 class="subtitle is-5">Haftungsausschluss</h2>
  <div class="content">
    <p>
      Bei der Verlinkung bzw. Übernahme von Informationsangeboten Dritter hat die Betreiberin
       alle vertretbaren Maßnahmen unternommen, die fremden Inhalte daraufhin zu überprüfen, 
       ob durch sie eine zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. 
      Eine permanente inhaltliche Kontrolle der verknüpften externen Seiten ist 
       jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. 
      Sollte die Betreiberin feststellen oder von anderen darauf hingewiesen werden, 
       dass ein Angebot eines Dritten, welches per Link verknüpft wurde, 
       eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, 
       wird es den Link auf dieses Angebot unverzüglich aufheben. 
      Die Betreiberin distanziert sich ausdrücklich von derartigen Inhalten.
    </p>
    <p>
      Die Verwendung sämtlicher Daten erfolgt auf eigenes Risiko. 
      Für Schäden, die sich aus der Verwendung abgerufener Informationen ergeben, 
       übernimmt die Betreiberin keine Haftung.
    </p>
    <p>
      Diese Hinweise gelten in gleicher Weise für Subdomains [subdomain].evidence.bbaw.de, 
       deren inhaltliche Verantwortlichkeit bei den jeweiligen Autoren liegt.
    </p>
    <p>
      Bei allgemeinen Fragen zu den Nutzungsbedingungen oder in Zweifelsfällen setzen 
       Sie sich bitte mit uns in Verbindung: evidence@bbaw.de
    </p>
  </div>

</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TermsDE",
  components: {},
  setup(){}
});
</script>